import { LOCAL_STORAGE_REDIRECT_PATH_KEY } from '@dtx-company/true-common/src/constants/auth'
import { SSOLoginOrSignupState, StartSSORedirect } from '../sso'
import { logger } from '@dtx-company/logger'
import { prelogin } from '@dtx-company/ithaca-sdk/src'
import { useCallback, useState } from 'react'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useRouter } from 'next/router'
import isEmail from 'validator/lib/isEmail'

export function usePrelogin(): SSOLoginOrSignupState {
  const isMounted = useIsMounted()
  const [emailIsSSOEnabled, setEmailIsSSOEnabled] = useState(false)
  const [ssoRedirectUrl, setSSORedirectUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const checkIfEmailSSOEnabled = useCallback(
    async (email: string): Promise<void> => {
      if (!isEmail(email)) return

      setLoading(true)

      try {
        const preloginData = await prelogin({ email })

        // SSO outbound url
        if (preloginData?.address) {
          setSSORedirectUrl(preloginData.address)
          setEmailIsSSOEnabled(true)
        } else {
          setSSORedirectUrl('')
          setEmailIsSSOEnabled(false)
        }
      } catch (e) {
        logger.logError(e, { technicalArea: 'authentication' })
      } finally {
        setLoading(false)
      }
    },
    [setSSORedirectUrl, setEmailIsSSOEnabled]
  )

  const { query } = useRouter()

  const startSSORedirectCallback: StartSSORedirect = async redirectUrl => {
    try {
      const isValidRedirectUrl = redirectUrl?.length && redirectUrl.includes('https://')
      const redirectTo = isValidRedirectUrl
        ? encodeURIComponent(redirectUrl)
        : (query.redirectTo as string)

      if (isMounted() && ssoRedirectUrl) {
        if (redirectTo) {
          /*
            this gets cleared either after use on /frontegg-sso-redirect,
            or the next time a user loads a page that is not /frontegg-sso-redirect.
            The latter is handled in _app

            This does not get respected across www <-> app subdomains and
            should only be free-qr-code-generator and /page
          */
          window.localStorage.setItem(LOCAL_STORAGE_REDIRECT_PATH_KEY, redirectTo)
        }
        window.location.href = ssoRedirectUrl
      }
    } catch (e) {
      logger.logError(e, { technicalArea: 'authentication' })
    }
  }

  /**
   * Needs to be wrapped in an <code>if(emailIsSSOEnabled) { startSSORedirect() }</code>
   * @param redirectUrl Use full URI ie
   */
  const startSSORedirect = useCallback(startSSORedirectCallback, [
    query.redirectTo,
    isMounted,
    ssoRedirectUrl
  ])

  const resetSSOEnabled = (): void => {
    setSSORedirectUrl('')
    setEmailIsSSOEnabled(false)
  }

  return {
    emailIsSSOEnabled,
    checkIfEmailSSOEnabled,
    startSSORedirect,
    ssoRedirectHtml: null, //always null in order to not render the html redirect component <SSORedirect /> when frontegg is enabled
    resetSSOEnabled,
    loading
  }
}
